<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-h1">Minha saúde</h1>
      <div class="content">
      
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";

export default {
    setup(){

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>